import { authInstance as axios } from 'utils/axiosInterceptor';

const retrieveFintectureWebhooks = async ({
  pageSize = 10,
  pageIndex = 1,
  event,
  search,
}) => {
  const params = {};

  if (pageSize && pageIndex) {
    params.pageSize = pageSize;
    params.pageIndex = pageIndex;
  }

  if (event) {
    params.event = event;
  }

  if (search) {
    params.search = search;
  }

  return axios.get(
    `${process.env.REACT_APP_FINTECTURE_WEBHOOK_URL}/fintecture-webhook`,
    {
      params,
    }
  );
};

const fetchWebhookDetails = async (webhookId) => {
  return axios.get(
    `${process.env.REACT_APP_FINTECTURE_WEBHOOK_URL}/fintecture-webhook/${webhookId}`
  );
};

export default {
  retrieveFintectureWebhooks,
  fetchWebhookDetails,
};
