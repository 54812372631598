import {
  REMOVE_ERROR,
  FETCHING_FINTECTURE_WEBHOOK_SUCCESS,
  FETCHING_FINTECTURE_WEBHOOK_FAILED,
  RESET_FINTECTURE_WEBHOOK,
} from 'actions/Types';

const initialState = {
  data: {
    docs: [],
    hasNextPage: true,
    hasPrevPage: false,
    limit: 10,
    nextPage: 2,
    page: 1,
    pagingCounter: 1,
    prevPage: null,
    totalDocs: 10,
    totalPages: 1,
  },
  loading: false,
  error: null,
  LastEvaluatedKey: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case REMOVE_ERROR:
      return {
        ...state,
        error: null,
      };
    case FETCHING_FINTECTURE_WEBHOOK_SUCCESS: {
      // concat state.data.docs with action.payload.docs to avoid duplicates
      const ids = new Set(state.data.docs.map((d) => d._id));
      const docs = [
        ...state.data.docs,
        ...(action?.payload?.docs?.filter((d) => !ids.has(d._id)) || []),
      ];

      return {
        ...state,
        data: {
          ...action.payload,
          docs,
        },

        loading: false,
        error: null,
      };
    }
    case FETCHING_FINTECTURE_WEBHOOK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case RESET_FINTECTURE_WEBHOOK:
      return {
        ...state,
        error: null,
        data: initialState.data,
      };
    default:
      return state;
  }
};
