export const FINTECTURE_WEBHOOK_TYPE = {
  PAYMENT_SESSION_STATUS_PAYMENT_CREATED:
    'payment_session.status.payment_created',
  PAYMENT_SESSION_TRANSFER_STATES_COMPLETED:
    'payment_session.transfer_states.completed',
  PAYMENT_SESSION_TRANSFER_STATES_PENDING:
    'payment_session.transfer_states.pending',
  PAYMENT_SESSION_STATUS_PAYMENT_PENDING:
    'payment_session.status.payment_pending',
  PAYMENT_SESSION_STATUS_PAYMENT_UNSUCCESSFUL:
    'payment_session.status.payment_unsuccessful ',
};

export const FINTECTURE_WEBHOOK_TYPE_LIST = Object.entries(
  FINTECTURE_WEBHOOK_TYPE
).map(([key, value]) => ({
  value,
  label: key
    .split('_')
    .map((ele) => `${ele[0].toUpperCase()}${ele.slice(1).toLowerCase()}`)
    .join(' '),
}));
