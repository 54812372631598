import React, { useRef, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, groupBy } from 'lodash';
import PropTypes from 'prop-types';
import { Grid, Typography, Button, IconButton, Box } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
// import FilePreview from 'components/FilePreview';

import {
  fetchCreationCompanyForm,
  updateCreaForm,
} from 'actions/CompanyActions';

import S3FileService from 'services/S3FileService';
import { CREA_FORM_ACTION } from 'constants/creaForm';

const useStyles = makeStyles(() => ({
  heading: {
    color: '#3d319d',
  },
  block: {
    marginBottom: '200px',
  },
  uploadDocument: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
  },
  addDocumentContainer: {
    width: '50%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  addDocument: {
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '150px',
    width: '60%',
    border: '#02cbcd dotted 3px',
    borderRadius: '18px',
    cursor: 'pointer',
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '2%',
    gap: '5%',
  },
  document: {
    width: '80%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '5%',
  },
}));

const UploadDocument = (props) => {
  const { companyOId } = props.match.params;
  let { docTitle } = props.match.params;
  docTitle = decodeURIComponent(docTitle);

  const fileToAdd = docTitle === 'addDoc';

  const history = useHistory();

  const classes = useStyles();

  const dispatch = useDispatch();

  const inputFile = useRef(null);

  // const [inputName, setInputName] = useState('Cliquez pour charger un fichier');
  const [document, setDocument] = useState();

  const creaCompanyForm = useSelector(
    (state) => state.companies && state.companies.creaFormData
  );
  const [updatedForm, setUpdatedForm] = useState(null);

  useEffect(() => {
    if (isEmpty(creaCompanyForm)) {
      dispatch(fetchCreationCompanyForm(companyOId));
    }
  });

  const onButtonClick = () => {
    inputFile.current.click();
  };

  const onChangeFile = async (event) => {
    event.stopPropagation();
    event.preventDefault();

    const { files } = event.target;

    const { alternativeId } = creaCompanyForm;

    if (files.length > 0) {
      const file = {
        name: files[0].name,
        fileType: files[0].type,
        url: URL.createObjectURL(files[0]),
      };

      // setInputName(file.name);
      setDocument(file);
      const key = await S3FileService.uploadDocument(alternativeId, files[0]);

      let docIdx = creaCompanyForm.documents.findIndex(
        (formDoc) => formDoc.title === docTitle
      );

      // Looking for the last version of the doc to replace the last one
      const groupedFiles = groupBy(creaCompanyForm.documents, 'title');
      const filesLastVersion = Object.keys(groupedFiles).map((k) => {
        const lastIndex = groupedFiles[k].length - 1;
        return groupedFiles[k][lastIndex];
      });
      const filelastVersion = filesLastVersion.find(
        (f) => f.title === docTitle
      );
      if (
        filelastVersion &&
        filelastVersion.version > 1 &&
        filelastVersion.key
      ) {
        docIdx = creaCompanyForm.documents.findIndex(
          (formDoc) => formDoc.key === filelastVersion.key
        );
      }

      const updatedCreaCompanyForm = { ...creaCompanyForm };
      if (docIdx === -1) {
        // new doc to add

        const newFileToAdd = {
          title: file.name,
          source: 'BBF',
          signBy: null,
          recipients: [],
          notary: false,
          filename: file.name,
          key,
        };
        updatedCreaCompanyForm.documents.push(newFileToAdd);
      } else {
        updatedCreaCompanyForm.documents[docIdx] = {
          ...updatedCreaCompanyForm.documents[docIdx],
          key,
        };
      }
      setUpdatedForm(updatedCreaCompanyForm);
    }
  };

  const handleUpdateCreaForm = async () => {
    const { _id: formOId } = updatedForm;
    await dispatch(
      updateCreaForm(companyOId, formOId, {
        ...updatedForm,
        actionType: CREA_FORM_ACTION.UPLOAD_DOCUMENT,
      })
    );
    history.push(`/reviewdocs/${companyOId}`);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Box display="flex" alignItems="center" mb={5}>
          <Button
            className={classes.backBtn}
            variant="contained"
            color="primary"
            onClick={() => history.goBack()}
          >
            Back
          </Button>
        </Box>
        <Grid item xs={12} className={classes.uploadDocument}>
          <Typography
            variant="h4"
            style={{ textAlign: 'center', paddingBottom: '2%' }}
            className={classes.heading}
          >
            {fileToAdd ? 'Ajouter un document' : docTitle}
          </Typography>
          <div className={classes.addDocumentContainer}>
            <div className={classes.addDocument} onClick={onButtonClick}>
              <input
                type="file"
                accept="image/*,.pdf"
                style={{ display: 'none' }}
                ref={inputFile}
                onChange={onChangeFile}
              />
              <Typography variant="subtitle1" style={{ color: '#02cbcd' }}>
                Cliquez pour charger un fichier
              </Typography>
            </div>
            {document && (
              <div className={classes.document}>
                <Typography variant="paragraphe">{document.name}</Typography>
                <IconButton edge="end" aria-label="download">
                  <DeleteIcon
                    style={{ color: 'red' }}
                    onClick={() => {
                      setDocument(null);
                    }}
                  />
                </IconButton>
              </div>
            )}
          </div>
        </Grid>
        {/* <Grid item xs={7}>
          <Typography
            variant="h4"
            style={{ textAlign: 'center' }}
            className={classes.heading}
          >
            {docTitle}
          </Typography>

          {document && (
            <FilePreview
              content={{
                type:
                  document.fileType === 'application/pdf'
                    ? 'application/pdf'
                    : 'image',
                value: document.url,
              }}
            />
          )}
        </Grid> */}
        <Grid item xs={12}>
          <div className={classes.actions}>
            <Link to={`/reviewdocs/${companyOId}`}>
              <Button variant="contained" color="default">
                RETOUR
              </Button>
            </Link>
            {document && updatedForm && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleUpdateCreaForm()}
              >
                Entregistrer
              </Button>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};

UploadDocument.propTypes = {
  match: PropTypes.object,
};

export default UploadDocument;
