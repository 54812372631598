import fintectureService from 'services/fintectureService';
import {
  RESET_FINTECTURE_WEBHOOK,
  FETCHING_FINTECTURE_WEBHOOK_FAILED,
  START_PROGRESS,
  END_PROGRESS,
  FETCHING_FINTECTURE_WEBHOOK_SUCCESS,
} from 'actions/Types';
import { store } from '../../store';

const removeError = async () => {
  store.dispatch({
    type: RESET_FINTECTURE_WEBHOOK,
  });
};

const refreshFintectureWebhooks = async (query) => {
  try {
    store.dispatch({
      type: RESET_FINTECTURE_WEBHOOK,
    });

    const result = await fintectureService.retrieveFintectureWebhooks(query);

    store.dispatch({
      type: FETCHING_FINTECTURE_WEBHOOK_SUCCESS,
      payload: result.data,
    });
    return result.data;
  } catch (error) {
    store.dispatch({
      type: FETCHING_FINTECTURE_WEBHOOK_FAILED,
      payload: error,
    });

    return error;
  }
};

const fetchFintectureWebhooks = async (query) => {
  try {
    const result = await fintectureService.retrieveFintectureWebhooks(query);

    if (result.error) {
      throw result;
    }

    store.dispatch({
      type: FETCHING_FINTECTURE_WEBHOOK_SUCCESS,
      payload: result.data,
    });

    return result.data;
  } catch (error) {
    store.dispatch({
      type: FETCHING_FINTECTURE_WEBHOOK_FAILED,
      payload: error,
    });

    return error;
  }
};

const fetchFintectureWebhookDetails = async (webhookId) => {
  try {
    store.dispatch({
      type: START_PROGRESS,
    });
    const result = await fintectureService.fetchWebhookDetails(webhookId);

    if (result.error) {
      throw result;
    }
    store.dispatch({
      type: END_PROGRESS,
    });
    return result.data;
  } catch (error) {
    store.dispatch({
      type: FETCHING_FINTECTURE_WEBHOOK_FAILED,
      payload: error,
    });
    store.dispatch({
      type: END_PROGRESS,
    });
    return error;
  }
};

export default {
  fetchFintectureWebhooks,
  fetchFintectureWebhookDetails,
  refreshFintectureWebhooks,
  removeError,
};
