import { combineReducers } from 'redux';

import LoggedUserReducer from './LoggedUserReducer';
import CompanyReduser from './CompanyReducer';
import AppReducer from './AppReducer';
import WebhookReducer from './WebhookReducer';
import BridgeWebhookReducer from './bridgeWebhookReducer';
import PayoutReducer from './PayoutReducer';
import DocuSignReducer from './DocuSignReducer';
import NotificationReducer from './notificationReducer';
import fintectureWebhookReducer from './fintectureWebhookReducer';
import VATRatesReducer from './VATRatesReducer';
import LoadingReducer from './LoadingReducer';

export default combineReducers({
  // logged User
  loggedUser: LoggedUserReducer,
  // company list : TODO rename into companyList
  companies: CompanyReduser,
  payout: PayoutReducer,
  app: AppReducer,
  webhook: WebhookReducer,
  bridgeWebhook: BridgeWebhookReducer,
  fintectureWebhook: fintectureWebhookReducer,
  docusign: DocuSignReducer,
  notification: NotificationReducer,
  vatRates: VATRatesReducer,
  // App loading
  isLoading: LoadingReducer,
});
